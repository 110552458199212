/******************************* General style ************************************/
.terroir-from-space {
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	z-index: -1;
	background-position: center center;
	background-size: cover;
	padding: 1rem;
	color: #ffffff;
	text-shadow: 0 0 8px #66757f;
	font-family: 'Changa', sans-serif;
}

.terroir-from-space-home {
	background-image: url('./images/pictures/vineyard-1.jpg');
}

.terroir-from-space-presentation {
	background-image: url('./images/pictures/vineyard-2.jpg');
}

.terroir-from-space-vision {
	background-image: url('./images/pictures/space-1.jpg');
}

.terroir-from-space-team {
	background-image: url('./images/pictures/space-3.jpg');
}

.terroir-from-space-contacts {
	background-image: url('./images/pictures/vineyard-3.jpg');
}

.terroir-from-space-content {
	margin: auto;
	align-items: center;
}

.jumbotron {
  background: none;
	margin: 0;
}

.terroir-from-space-home .row {
	align-items: center;
}

.title-container {
	display: flex;
}

#title {
	font-size: 4.5rem;
}

#trusted-by {
	font-size: 1.5rem;
}

.terroir-from-space-presentation .lead {
	text-align: justify;
}

.terroir-from-space-team .lead {
	text-align: justify;
}
/******************************* Header *******************************************/
div.navbar-nav {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	align-items: flex-end;
}

.navbar-light .navbar-nav .nav-link {
	font-size: 1.5rem;
	font-family: 'Changa', sans-serif;
	color: white;
	text-shadow: 0 0 6px #66757f;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link:hover{
	text-shadow: 0 0 6px #f8f9fa;
}

.navbar-toggler, .navbar-toggler:hover, .navbar-toggler:active, .navbar-toggler:focus {
  border:none;
	outline: none;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28250, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .navbar-light .navbar-nav .nav-link {
		font-size: 1.05rem;
		line-height: 130%;
	}
	.navbar-brand img {
		width: 37.5px;
		height: 37.5px;
	}
	#title {
		font-size: 2.5rem;
		margin-top: 20px;
	}
	h1.display-5 {
		margin: 0;
	}
	.lead {
		font-size: 0.8rem;
	}
	.jumbotron {
		padding: 0;
		margin: 0;
	}
	.jumbotron .container {
		padding: 0;
	}
	.terroir-from-space-presentation .container {
		padding: 25px 5px 0 5px;
	}
	.terroir-from-space-team .container {
		padding: 20px 5px 0 5px;
	}
	img.supporter-logo {
		height: 35px;
	}
}

/******************************* VideoPlayer **************************************/
.video-container {
	position: relative;
  padding-top: 56.25%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

/******************************* Transitions **************************************/
.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

section.route-section {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
}